<template>
  <div>
    <div class="get-plan-toast fixed" :class="{ show }">
      <div class="gpt-title mx-auto">获取方案和报价</div>
      <div class="private-close text-32px" @click="hideModal"></div>

      <div class="form-container mt-8px">
        <div class="hover-pop-item">
          <img class="hover-pop-icon hover-pop-name" src="/static-images/getplan/ic_1.png" alt="" />
          <input class="hover-pop-input hover-pop-name-input" type="text" v-model="form.name" placeholder="请输入您的姓名" />
        </div>
        <div class="hover-pop-item">
          <img class="hover-pop-icon hover-pop-name" src="/static-images/getplan/ic_3.png" alt="" />
          <input class="hover-pop-input hover-pop-name-input" v-model="form.company" type="text" placeholder="请输入您的公司名称" />
        </div>
        <div class="hover-pop-item">
          <img class="hover-pop-icon hover-pop-name" src="/static-images/getplan/ic_2.png" alt="" />
          <input class="hover-pop-input hover-pop-name-input" v-model="form.phone" type="text" placeholder="请输入您的手机号" />
        </div>
        <div class="hover-pop-item">
          <img class="hover-pop-icon hover-pop-name" src="/static-images/getplan/ic_4.png" alt="" />
          <select
            class="hover-pop-input hover-pop-plan-input pl-6px!"
            placeholder="请选择您车辆的所属类型"
            @change="onPlanChange"
            :style="{ color: selected ? '#666' : '#969393' }"
          >
            <option :selected="form.plan === t.value" :disabled="!t.value" :value="t.value" :key="i" v-for="(t, i) in options.plan">
              {{ t.label }}
            </option>
          </select>
        </div>
        <div class="hover-pop-item full h-96px! pt-10px!">
          <img class="hover-pop-icon hover-pop-name" src="/static-images/getplan/ic_6.png" alt="" />
          <textarea class="hover-pop-input h-full!" v-model="form.remark" placeholder="请在此留下您的具体需求，我们会尽快与您联系。" />
        </div>
        <div class="hover-pop-item full">
          <img class="hover-pop-check-icon" :src="validateImg" alt="" />
          <input class="hover-pop-input hover-pop-check-input" v-model="form.code" type="text" placeholder="请输入验证码" />
        </div>
      </div>
      <div class="submit w-200px h-32px flex-center text-16px mx-auto mt-12px cursor-pointer" @click="submit">提交</div>
    </div>
    <img class="plan-toast-small" :class="{ show }" @click="showModal" src="./assets//toast-small.png" alt="" />
    <div class="hover-pop-success" v-if="showSuccess">
      <div class="private-back"></div>
      <div class="private-content">
        <div class="private-title">
          提示
          <img class="private-close success-close" @click="hideModal" src="/static-images/getplan/ic_close.png" />
        </div>
        <div class="private-inner">
          <img class="success-img" src="/static-images/getplan/ic_success.png" alt="" />
          <div class="success-txt">提交成功！我们会尽快联系您</div>

          <div class="private-button success-button" @click="hideModal">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const div = document.createElement('div');
div.className = 'get-plan-toast-shadow';
export default {
  data() {
    return {
      form: {
        name: '',
        company: '',
        phone: '',
        remark: '',
        plan: '',
        code: ''
      },
      options: {
        plan: [
          {
            label: '请选择您的方案类型',
            value: ''
          },
          {
            label: '商砼车',
            value: '商砼车'
          },
          {
            label: '公务车',
            value: '公务车'
          },
          {
            label: '物流车',
            value: '物流车'
          },
          {
            label: '押运车客车',
            value: '押运车客车'
          },
          {
            label: '危险品车',
            value: '危险品车'
          },
          {
            label: '环卫车',
            value: '环卫车'
          },
          {
            label: '执法车',
            value: '执法车'
          },
          {
            label: '渔船',
            value: '渔船'
          },
          {
            label: '渣土车',
            value: '渣土车'
          },
          {
            label: '叉车',
            value: '叉车'
          },
          {
            label: '校车',
            value: '校车'
          },
          {
            label: '冷链车',
            value: '冷链车'
          },
          {
            label: '滴滴车',
            value: '滴滴车'
          }
        ]
      },
      show: false,
      showPrivateInformation: false,
      showSuccess: false,
      selected: false,
      stage: [10, 20, 40, 80],
      stageIndex: 1,
      countDown: 0,
      timer: null,
      validateImg: null,
      alreadySubmit: sessionStorage.getItem('already-submit-get-plan')
    };
  },
  mounted() {
    this.startTrigger();
  },
  methods: {
    onPlanChange(e) {
      this.form.plan = e.target.value;
      this.selected = true;
    },
    startTrigger() {
      this.stopTrigger();
      if (!this.countDown) {
        this.countDown = this.stage[this.stageIndex];
      }
      this.timer = setInterval(() => {
        const name = this.$router.currentRoute.name;
        const condition = name === 'get-plan' || this.alreadySubmit;
        const needMinus = condition ? 0 : 1;
        this.countDown -= needMinus;
        this.countDown = Math.max(0, this.countDown);
        if (!this.countDown) {
          this.showModal();
        }
      }, 1000);
    },
    stopTrigger() {
      clearInterval(this.timer);
    },
    hideModal() {
      this.show = false;
      this.showPrivateInformation = false;
      this.showSuccess = false;
      this.hideBackground();
      this.stageIndex = Math.min(this.stageIndex + 1, this.stage.length - 1);
      this.startTrigger();
    },
    showModal() {
      this.getCode();
      this.show = true;
      this.showBackground();
      this.stopTrigger();
      this.countDown = this.stage[this.stageIndex];
    },
    showBackground() {
      document.body.appendChild(div);
    },
    hideBackground() {
      document.body.removeChild(div);
    },
    submit() {
      const tips = [];
      const emptyValue = [];
      if (!this.form.name) {
        emptyValue.push('姓名');
      }
      if (!this.form.phone) {
        emptyValue.push('联系方式');
      }
      if (!this.form.code) {
        emptyValue.push('验证码');
      }
      emptyValue.length && tips.push(`请填写 ${emptyValue.join('、')}`);
      if (this.form.phone.length !== 11) {
        tips.push('请输入正确的手机号');
      }
      if (tips.length) {
        return alert(tips.join('\n'));
      }
      axios
        .post(`https://api.dev.zhkcjt.com/support/user/websiteCommit/${this.form.code}`, {
          name: this.form.name,
          mobile: this.form.phone,
          company: this.form.company,
          plan: this.form.plan,
          remark: this.form.remark,
          source: GB_SOURCE || '未知',
          type: 'gps'
        })
        .then(res => {
          if (res.data.status !== 1) {
            this.getCode()
            alert("验证码有误，请重新输入");
          } else {
            this.showSuccess = true;
            sessionStorage.setItem('already-submit-get-plan', true);
            this.alreadySubmit = true;
          }
        });
    },
    getCode() {
      axios.get('https://api.dev.zhkcjt.com/support/other/captcha').then(res => {
        this.validateImg = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(res.data);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.get-plan-toast {
  background: url('./assets/toast-bg.png') center no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  z-index: 9999;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s;
  width: 0;
  height: 0;
  padding: 0;
  left: 40px;
  top: 100%;
  transform: translate(0, calc(-100% - 40px));
  &.show {
    width: 720px;
    height: 420px;
    padding: 40px 40px 0 220px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 32px;
    background: #4e99fd;
  }
  * {
    box-sizing: border-box;
  }
  .private-close {
    position: absolute;
    right: 16px;
    top: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: url('./assets/close-img.png') center no-repeat;
  }

  .gpt-title {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 36px;
    color: #096dd9;
    line-height: 60px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .form-container {
    display: grid;
    grid-template-columns: repeat(2, 225px);
    grid-template-rows: repeat(2, auto);
    column-gap: 16px;
    row-gap: 16px;
    .full {
      grid-column: 1 / 3;
      align-items: flex-start;
      textarea {
        resize: none;
      }
    }
  }
  .hover-pop-icon {
    width: 16px;
    flex-shrink: 0;
  }
  .hover-pop-item {
    display: flex;
    align-items: center;
    color: red;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #e8e8e8;
    padding: 0 12px;
    height: 36px;
  }
  .hover-pop-input {
    flex-grow: 1;
    border-radius: 8px 8px 8px 8px;
    border: none;
    font-size: 16px;
    height: 36px;
    padding: 0 10px;
    outline: none;
    color: #666;
    background-color: transparent;
    min-width: 0;
    &::placeholder {
      color: #969393;
    }
  }
  .hover-pop-check-icon {
    object-fit: contain;
    height: 100%;
    cursor: pointer;
  }

  .private {
    font-size: 12px;
    color: #999999;
    text-align: center;
    margin-top: 40px;
    .sp {
      color: #4e99fd;
      cursor: pointer;
    }
  }

  .submit {
    background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
    border-radius: 5px;
    color: white;
  }
}
.plan-toast-small {
  width: 80px;
  height: 105px;
  position: fixed;
  transition: all 0.2s;
  left: 40px;
  bottom: 40px;
  opacity: 1;
  cursor: pointer;
  &.show {
    opacity: 0;
  }
}
.privateBox,
.hover-pop-success {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  .private-back {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 9999;
  }
  .private-content {
    position: fixed;
    width: 800px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    .private-title {
      color: white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      background: #4e99fd;
      position: relative;
      .private-close {
        position: absolute;
        right: 24px;
        top: 22px;
        width: 16px;
        cursor: pointer;
      }
    }
    .private-inner {
      font-size: 14px;
      line-height: 2;
      background: white;
      text-align: left;
      padding: 40px;
      padding-top: 10px;
      .private-h1 {
        color: #666;
      }
      .private-h2 {
        color: #333;
      }
      .private-h3 {
        color: #999;
      }
      .private-button {
        width: 144px;
        height: 48px;
        background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
        border-radius: 2px 2px 2px 2px;
        font-size: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 50px;
        cursor: pointer;
      }
    }
  }
}
.hover-pop-success {
  height: 586px;
  width: 795px;
  .private-back {
    display: none;
  }
  .private-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .success-txt {
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 500;
      font-size: 16px;
      color: #52c41a;
      line-height: 16px;
    }
  }
  .success-img {
    width: 360px;
  }
}
</style>
